import Web3 from 'web3';
import React from 'react';

import { ethers } from "ethers";

import QRCode from 'qrcode';

import AbiRouter0x from "./contracts/Router0x.json";


import { useState, useEffect } from "react";
import './Land.css';

import frame from './frame.png';
import comercioeng from './weaccept.png';
import comercioesp from './aceptamos.png';
import promocion from './PROMOCION.png';
import aceptacripto from './ACEPTAMOSCRYPTO.png';
//import logo from './WD13.png';
//import logo from './WEBTHREE2.png';
import logo from './pw3bis.png';

import promotion from './PROMOTION.png';
import accept from './WEACCEPTQR.png';
import ok from './ok.png';
import update from './update.png';


import {
   Button,
   Input,
} from 'reactstrap';




function Qrcode() {


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const thelang = urlParams.get("lang");


 
  //const web4 = new Web3('https://polygon-rpc.com/');    


  const WEB3_ROUTER_ADDRESS137 = '0x59E0c044e55Bce7d26397536b396567762c36E4F';
  const WEB3_ROUTER_ADDRESS56 = '0x846cE1068B61eCC408b84034A0d9d5D64933160C';
  const WEB3_ROUTER_ADDRESS43114 = '0x150aE9FE2f79FfA41c1BA00aaCAED52F02A004a1';


  const CHAIN137 = 'https://polygon-rpc.com/';
  const CHAIN56 = 'https://bscrpc.com';
  //const CHAIN250 = 'https://rpc.ftm.tools';
  const CHAIN43114 = 'https://avalanche.public-rpc.com';


  const provider137 = new Web3(CHAIN137);
  const provider56 = new Web3(CHAIN56);
  //const provider250 = new Web3(CHAIN250);
  const provider43114 = new Web3(CHAIN43114);


  
  const WSSCHAIN137 = 'wss://convincing-fabled-sky.matic.discover.quiknode.pro/928a76ae7d896cd651d1e5397c51ad7ae3406c31/';
  const WSSCHAIN56 = 'wss://bsc.getblock.io/62c55a95-23bf-4cf2-8cdd-c551ad9ffc00/mainnet/';
  //const WSSCHAIN250 = 'wss://ftm.getblock.io/62c55a95-23bf-4cf2-8cdd-c551ad9ffc00/mainnet/';
  const WSSCHAIN43114 = 'wss://avax.getblock.io/62c55a95-23bf-4cf2-8cdd-c551ad9ffc00/mainnet/ext/bc/C/ws?api_key=62c55a95-23bf-4cf2-8cdd-c551ad9ffc00';
  

  const [url, setUrl] = useState('');
  const [qr, setQr] = useState('');
  const [red, setRed] = useState(0);
  const [token, setToken] = useState(0);
  const [wallet, setWallet] = useState();
  const [mensaje, setmensaje] = useState("");
  const [mensaje1, setmensaje1] = useState("");
  const [thetype, setthetype] = useState(0);
  const [amount, setamount] = useState();
  const [memo, setmemo] = useState();
  const [decimalstoken, setdecimalstoken] = useState(0);
  const [datalink, setdatalink] = useState('');
  
  const [listen, setlisten] = useState(0);
  const [confirmed, setconfirmed] = useState(0);
  const [amountfixed, setamountfixed] = useState(0);
  const [lang, setlang] = useState(1);


  const [verdatos, setverdatos] = useState('');

  const [polygonflag, setpolygonflag] = useState(0);
  const [bnbflag, setbnbflag] = useState(0);
  //const [fantomflag, setfantomflag] = useState(0);
  const [avalancheflag, setavalancheflag] = useState(0);


  const [eid137, seteid137] = useState(0);
  const [esender137, setesender137] = useState('');
  const [ehash137, setehash137] = useState('');
  //const [eblock137, seteblock137] = useState(0);

  
  const [eid56, seteid56] = useState(0);
  const [esender56, setesender56] = useState('');
  const [ehash56, setehash56] = useState('');
  //const [eblock56, seteblock56] = useState(0);


  //const [eid250, seteid250] = useState(0);
  //const [esender250, setesender250] = useState('');
  //const [ehash250, setehash250] = useState('');
  //const [eblock250, seteblock250] = useState(0);


  const [eid43114, seteid43114] = useState(0);
  const [esender43114, setesender43114] = useState('');
  const [ehash43114, setehash43114] = useState('');
  //const [eblock43114, seteblock43114] = useState(0);



  const [sub1, setsub1] = useState('');
  const [sub2, setsub2] = useState('');
  //const [sub3, setsub3] = useState('');
  const [sub4, setsub4] = useState('');


  const [status137, setstatus137] = useState('');
  const [status56, setstatus56] = useState('');
  //const [status250, setstatus250] = useState('');
  const [status43114, setstatus43114] = useState('');


  const [idstatus137, setidstatus137] = useState(1);
  const [idstatus56, setidstatus56] = useState(1);
  //const [idstatus250, setidstatus250] = useState(1);
  const [idstatus43114, setidstatus43114] = useState(1);


  const [confirmaciones137, setconfirmaciones137] = useState(0);
  const [confirmaciones56, setconfirmaciones56] = useState(0);
  //const [confirmaciones250, setconfirmaciones250] = useState(0);
  const [confirmaciones43114, setconfirmaciones43114] = useState(0);


  const [ticking, setTicking] = useState(false);
  const [nextupdate, setnextupdate] = useState(0);

  const [closetrade, setclosetrade] = useState(false);

  

    useEffect(() => {
      
     
      if (ticking) {

        const interval = setInterval(() => {
              updateStatus();
            }, 10000);
        
            return () => clearInterval(interval);
        
        }


  },[nextupdate, ticking]);



  useEffect(() => {
      
    if (thelang == 1) {
      setlang(1);
    }

    if (thelang == 2) {
      setlang(2);
    }



},[]);








  function SelectLanguage() {

    
    setmensaje("");
    setmensaje1("");


    if (lang == 1) {
      setlang(2);
    } 
  
    if (lang == 2) {
      setlang(1);
    } 
  
  
  }



  const GenerateQRCode = () => {

    setmensaje("");
    setmensaje1("");
    var theurl="";
    var generateok = 0;
    var errorpay = 0;
    var theamount = 0;
    var thememo = 0;

    setQr(theurl);
    setdatalink('');
    
    if (Web3.utils.isAddress(wallet) == true) {
      
      if (red == 137 || red == 56 || red == 43114) {

        if (Web3.utils.isAddress(token) == true) {

          if (thetype == 1 || thetype == 2) {

            if (thetype == 1) {

              theurl = wallet + ',' + red + ',' + token + ',1' + ',0' + ',0';
                setUrl(theurl);      
                generateok = 1;      
                setamount();
                setmemo();
                
            } 

            if (thetype == 2) {


                theamount = amount;
                thememo = memo;
                
                if (theamount == 0 || theamount === undefined || isNaN(theamount) || thememo == 0 || thememo === undefined || isNaN(thememo)) {

                  errorpay = 1;

                  generateok = 3;

                  if (lang == 1) {
                  setmensaje("Ingrese el importe a pagar y referencia");
                  }  
                
                  if (lang == 2) {
                  setmensaje("Fill the amount to pay and reference"); 
                  }  
                
                } else {
                
                thememo = String(Math.trunc(thememo));  
    
                  
                try{
                  theamount = String(ethers.utils.parseUnits(theamount, decimalstoken));
                  setamountfixed(theamount);  
                
                
                }catch(e){
    
                    errorpay = 1;  
                  
                    generateok = 4;

                      if (lang == 1) {
                      setmensaje("Maximo admitido " + decimalstoken + " decimales");
                      }  
                    
                      if (lang == 2) {
                      setmensaje("Maximum " + decimalstoken + " decimals"); 
                      }
                  
                  };
                }    


                if (errorpay == 0) {

                  theurl = wallet + ',' + red + ',' + token + ',2' + ',' + theamount + ',' + thememo;
                  setUrl(theurl);      
                  generateok = 1;      

              }  
            }  
          
        }
      }
    }
  }


  if (generateok == 1) {

    QRCode.toDataURL(theurl, {
      width: 400,
        margin: 2,
        color: {
            dark: '#335383FF',
            light: '#EEEEEEFF'
        }
    }, (err, theurl) => {
        if (err) return console.error(err)

        console.log(theurl)
        setQr(theurl);
    })

  }





  






if (generateok == 0) {
  if (lang == 1) {
  setmensaje("Datos Incompletos");
  }

  if (lang == 2) {
    setmensaje("Incomplete Form");
  }

}


}



const GenerateLink = () => {

  setmensaje("");
  setmensaje1("");
  var theurl="";
  var generateok = 0;
  var errorpay = 0;
  var theamount = 0;
  var thememo = 0;

  setQr(theurl);
  setdatalink('');
  

  if (Web3.utils.isAddress(wallet) == true) {
    
    if (red == 137 || red == 56 || red == 43114) {

      if (Web3.utils.isAddress(token) == true) {

        if (thetype == 1 || thetype == 2) {

          if (thetype == 1) {

            theurl = wallet + ',' + red + ',' + token + ',1' + ',0' + ',0';
              setUrl(theurl);      
              generateok = 2;      
              setamount();
              setmemo();
              
          } 

          if (thetype == 2) {


              theamount = amount;
              thememo = memo;
              
              if (theamount == 0 || theamount === undefined || isNaN(theamount) || thememo == 0 || thememo === undefined || isNaN(thememo)) {

                errorpay = 1;

                generateok = 3;


                if (lang == 1) {
                setmensaje("Ingrese el importe a pagar y referencia");
                }  
              
                if (lang == 2) {
                setmensaje("Fill the amount to pay and reference"); 
                }  
              
              } else {
              
              thememo = String(Math.trunc(thememo));  
  
                
              try{
                theamount = String(ethers.utils.parseUnits(theamount, decimalstoken));
                setamountfixed(theamount);    
              
              }catch(e){
  
                  errorpay = 1;  
                
                  generateok = 4;

                    if (lang == 1) {
                    setmensaje("Maximo admitido " + decimalstoken + " decimales");
                    }  
                  
                    if (lang == 2) {
                    setmensaje("Maximum " + decimalstoken + " decimals"); 
                    }
                
                };
              }    


              if (errorpay == 0) {

             

                theurl = wallet + ',' + red + ',' + token + ',2' + ',' + theamount + ',' + thememo;
                setUrl(theurl);      
                generateok = 2;      

            }  
          }  
        
      }
    }
  }
}


if (generateok == 2) {

setdatalink('https://pay.web3debit.shop/link?data=' + theurl);  

}




if (generateok == 0) {
if (lang == 1) {
setmensaje("Datos Incompletos");
}

if (lang == 2) {
  setmensaje("Incomplete Form");
}

}


}









function listenEvents() {
  
    var count = 0;

    var subscription1;
    var subscription2;
    //var subscription3;
    var subscription4;


    var wss137 = new Web3(WSSCHAIN137);  
    var wss56 = new Web3(WSSCHAIN56);  
    //var wss250 = new Web3(WSSCHAIN250);  
    var wss43114 = new Web3(WSSCHAIN43114);  

    var contract137 = new wss137.eth.Contract(AbiRouter0x, WEB3_ROUTER_ADDRESS137);
    var contract56 = new wss56.eth.Contract(AbiRouter0x, WEB3_ROUTER_ADDRESS56);  
    //var contract250 = new wss250.eth.Contract(AbiRouter0x, WEB3_ROUTER_ADDRESS250);  
    var contract43114 = new wss43114.eth.Contract(AbiRouter0x, WEB3_ROUTER_ADDRESS43114);  



    setverdatos(true);
    setlisten(1);
    setconfirmed(0);
  
    setpolygonflag(0);
    setbnbflag(0);
    //setfantomflag(0);
    setavalancheflag(0);

    seteid137(0);
    setesender137('');
    setehash137('');
    //seteblock137(0);
  
    seteid56(0);
    setesender56('');
    setehash56('');
    //seteblock56(0);
  

    //seteid250(0);
    //setesender250('');
    //setehash250('');
    //seteblock250(0);
  
    seteid43114(0);
    setesender43114('');
    setehash43114('');
    //seteblock43114(0);
  
  
  
  
  try{
  
    subscription2 = contract56.events.Routed({
      filter: {store: wallet},
      fromBlock: 'latest'
    })
  
  
  .on('data', function(event) {

    if (count == 0) {

    var edestchain = 0;
  
    var ethememo = event.returnValues.memo;
    var etheamount = event.returnValues.amountOut;
    var ethetoken = event.returnValues.tokenOut;
    var etochain = event.returnValues.destChain;
    //var isremoved = event.removed;  
    
    
    if (etochain == 0) {
      edestchain = 56;
    }
  
    if (etochain == 109) {
      edestchain = 137;
    }
  
    //if (etochain == 112) {
    //  edestchain = 250;
    //}


    if (etochain == 106) {
      edestchain = 43114;
    }


  
/////////////////////////////////////////////////////////////////////////////////////////

      var sametoken = 0;

      if (ethetoken.toLowerCase().localeCompare((token).toLowerCase()) == 0) {
          sametoken = 1;	
      }




if (ethememo == memo && etheamount == amountfixed && sametoken == 1 && edestchain == red) {
  
  count++;
  setconfirmed(1);    	

  subscription1.unsubscribe(function(error, success){
  });
  
  subscription2.unsubscribe(function(error, success){
  });        


  subscription4.unsubscribe(function(error, success){
  });        



  setlisten(2);
setQr('');

  setbnbflag(1);		

//var theblock = event.blockNumber;
//  seteblock56(theblock);

var thehash = event.transactionHash;
  setehash56(thehash);

  seteid56(event.returnValues.id);
  setesender56(event.returnValues.sender);

  setidstatus56(1);

  if (lang == 1) {
    setstatus56("Pendiente...");
  }

  if (lang == 2) {
    setstatus56("Pending...");
  }


setTicking(true);
setnextupdate(nextupdate + 1);

}

}

    });
  
    }catch(e){
  
  
}
  
    setsub2(subscription2);    




    try{
  
      subscription4 = contract43114.events.Routed({
        filter: {store: wallet},
        fromBlock: 'latest'
      })
    
    
    .on('data', function(event) {
    
      if (count == 0) {
    
      var edestchain = 0;
    
      var ethememo = event.returnValues.memo;
      var etheamount = event.returnValues.amountOut;
      var ethetoken = event.returnValues.tokenOut;
      var etochain = event.returnValues.destChain;
      //var isremoved = event.removed;  
      
      
      if (etochain == 0) {
        edestchain = 43114;
      }
    
      if (etochain == 109) {
        edestchain = 137;
      }
    
      if (etochain == 102) {
        edestchain = 56;
      }
    
    
    //  if (etochain == 112) {
    //    edestchain = 250;
    //  }
    
    
    
    /////////////////////////////////////////////////////////////////////////////////////////
    
        var sametoken = 0;
    
        if (ethetoken.toLowerCase().localeCompare((token).toLowerCase()) == 0) {
            sametoken = 1;	
        }
    
    
    
    
    if (ethememo == memo && etheamount == amountfixed && sametoken == 1 && edestchain == red) {
    
    count++;
    setconfirmed(1);    	
    
    subscription1.unsubscribe(function(error, success){
    });
    
    subscription2.unsubscribe(function(error, success){
    });        
    
    
    subscription4.unsubscribe(function(error, success){
    });        
    
    
    
    setlisten(2);
    setQr('');
    
    setavalancheflag(1);		
    
    //var theblock = event.blockNumber;
    //seteblock43114(theblock);
    
    var thehash = event.transactionHash;
    setehash43114(thehash);
    
    seteid43114(event.returnValues.id);
    setesender43114(event.returnValues.sender);
    
    setidstatus43114(1);
    
    if (lang == 1) {
      setstatus43114("Pendiente...");
    }
    
    if (lang == 2) {
      setstatus43114("Pending...");
    }
    
    
    setTicking(true);
    setnextupdate(nextupdate + 1);
    
    }
    
    }
    
      });
    
      }catch(e){
    
    
    }
    
      setsub4(subscription4);    
    
  
  
  













    try{
  
      subscription1 = contract137.events.Routed({
      filter: {store: wallet},
      fromBlock: 'latest'
    })



  .on('data', function(event) {




    if (count == 0) {

    var edestchain = 0;  

    var ethememo = event.returnValues.memo;
    var etheamount = event.returnValues.amountOut;
    var ethetoken = event.returnValues.tokenOut;
    var etochain = event.returnValues.destChain;
    //var isremoved = event.removed;      


    if (etochain == 0) {
      edestchain = 137;
    }

    if (etochain == 102) {
      edestchain = 56;
    }


    //if (etochain == 112) {
    //  edestchain = 250;
    //}


    if (etochain == 106) {
      edestchain = 43114;
    }


    var sametoken = 0;

    if (ethetoken.toLowerCase().localeCompare((token).toLowerCase()) == 0) {
        sametoken = 1;	
    }




    if (ethememo == memo && etheamount == amountfixed && sametoken == 1 && edestchain == red) {




      count++;
      setconfirmed(1);    	

      subscription1.unsubscribe(function(error, success){
      });
      
      subscription2.unsubscribe(function(error, success){
      });        

      subscription4.unsubscribe(function(error, success){
      });        



      setlisten(2);
setQr('');

      setpolygonflag(1);		

//var theblock = event.blockNumber;
//      seteblock137(theblock);

var thehash = event.transactionHash;
      setehash137(thehash);

      seteid137(event.returnValues.id);
      setesender137(event.returnValues.sender);


setidstatus137(1);

if (lang == 1) {
  setstatus137("Pendiente...");
}

if (lang == 2) {
  setstatus137("Pending...");
}




setTicking(true);
setnextupdate(nextupdate + 1);

}

}
    });
  
  }catch(e){

  }
  
setsub1(subscription1);    



  }
  
  
  async function updateStatus() {

    var thestatus137 = 0;
    var thestatus56 = 0;
    //var thestatus250 = 0;
    var thestatus43114 = 0;


    if (polygonflag == 1) {
    var receipt = await provider137.eth.getTransactionReceipt(ehash137);
  
    if (receipt == null) {
  
      thestatus137 = 1;
      setidstatus137(1);

      if (lang == 1) {
        setstatus137("Pendiente...");
      }
  
      if (lang == 2) {
        setstatus137("Pending...");
      }
  
    } else {
  
      if (receipt.logs[0].removed) {
  
        thestatus137 = 2;
        setidstatus137(2);
  
        if (lang == 1) {
          setstatus137("Removida");
        }
  
        if (lang == 2) {
          setstatus137("Removed");
        }				
  
      } else {
  
  
        if (!receipt.status) {
  
          thestatus137 = 2;
          setidstatus137(2);		
  
          if (lang == 1) {
            setstatus137("Rechazada");
          }
  
          if (lang == 2) {
            setstatus137("Rejected");
          }				
  
        } else {
  
          var latest_block = await provider137.eth.getBlockNumber();
  
          setconfirmaciones137(latest_block - receipt.blockNumber);

          if ((latest_block - receipt.blockNumber) <= 48) {
  
            thestatus137 = 1;
            setidstatus137(1);


            if (lang == 1) {
              setstatus137("Confirmando...");
            }
    
            if (lang == 2) {
              setstatus137("Confirming...");
            }												
  
          } else {
  
            thestatus137 = 3;
            setidstatus137(3);
            
            if (lang == 1) {
              setstatus137("Confirmada");
            }
    
            if (lang == 2) {
              setstatus137("Confirmed");
            }														
  
          }		
    
        }
  
      }
  
    }
    }
  

    

    



      if (avalancheflag == 1) {
        var receipt = await provider43114.eth.getTransactionReceipt(ehash43114);
      
        if (receipt == null) {
      
          thestatus43114 = 1;
          setidstatus43114(1);
    
          if (lang == 1) {
            setstatus43114("Pendiente...");
          }
      
          if (lang == 2) {
            setstatus43114("Pending...");
          }
      
        } else {
      
          if (receipt.logs[0].removed) {
      
            thestatus43114 = 2;
            setidstatus43114(2);
      
            if (lang == 1) {
              setstatus43114("Removida");
            }
      
            if (lang == 2) {
              setstatus43114("Removed");
            }				
      
          } else {
      
      
            if (!receipt.status) {
      
              thestatus43114 = 2;
              setidstatus43114(2);		
      
              if (lang == 1) {
                setstatus43114("Rechazada");
              }
      
              if (lang == 2) {
                setstatus43114("Rejected");
              }				
      
            } else {
      
              var latest_block = await provider43114.eth.getBlockNumber();
      
              setconfirmaciones43114(latest_block - receipt.blockNumber);
    
              if ((latest_block - receipt.blockNumber) <= 12) {
      
                thestatus43114 = 1;
                setidstatus43114(1);
    
    
                if (lang == 1) {
                  setstatus43114("Confirmando...");
                }
        
                if (lang == 2) {
                  setstatus43114("Confirming...");
                }												
      
              } else {
      
                thestatus43114 = 3;
                setidstatus43114(3);
                
                if (lang == 1) {
                  setstatus43114("Confirmada");
                }
        
                if (lang == 2) {
                  setstatus43114("Confirmed");
                }														
      
              }		
        
            }
      
          }
      
        }
        }
    
  
  
  
    if (bnbflag == 1) {
    var receipt = await provider56.eth.getTransactionReceipt(ehash56);
  
    if (receipt == null) {
  
      thestatus56 = 1;
      setidstatus56(1);	

      if (lang == 1) {
        setstatus56("Pendiente...");
      }
  
      if (lang == 2) {
        setstatus56("Pending...");
      }
  
    } else {
  
      if (receipt.logs[0].removed) {
  
        thestatus56 = 2;
        setidstatus56(2);	
  
        if (lang == 1) {
          setstatus56("Removida");
        }
  
        if (lang == 2) {
          setstatus56("Removed");
        }				
  
      } else {
  
  
        if (!receipt.status) {
  
          thestatus56 = 2;
          setidstatus56(2);		
  
          if (lang == 1) {
            setstatus56("Rechazada");
          }
  
          if (lang == 2) {
            setstatus56("Rejected");
          }				
  
        } else {
  
          var latest_block = await provider56.eth.getBlockNumber();
  
          setconfirmaciones56(latest_block - receipt.blockNumber);

          if ((latest_block - receipt.blockNumber) <= 20) {
  
            thestatus56 = 1;
            setidstatus56(1);	

            if (lang == 1) {
              setstatus56("Confirmando...");
            }
    
            if (lang == 2) {
              setstatus56("Confirming...");
            }												
  
          } else {
  
            thestatus56 = 3;
            setidstatus56(3);		
  
            if (lang == 1) {
              setstatus56("Confirmada");
            }
    
            if (lang == 2) {
              setstatus56("Confirmed");
            }														
  
          }		
    
        }
  
      }
  
    }
    }
  

    

    if (((polygonflag == 1 && thestatus137 == 1) || (avalancheflag == 1 && thestatus43114 == 1) || (bnbflag == 1 && thestatus56 == 1)) && thetype == 2 && listen == 2) {

      setnextupdate(nextupdate + 1);

    } else {

      setTicking(false);
      setclosetrade(true);
    }




  }
  
  





  function stopEvents() {
    
      setclosetrade(false);
      setverdatos('');
      setlisten(0);
      setconfirmed(0);

      sub1.unsubscribe(function(error, success){
      });

     sub2.unsubscribe(function(error, success){
     });

    
    sub4.unsubscribe(function(error, success){
    });



  }

  

  
  function closePay() {
  

    setconfirmaciones137(0);
    setconfirmaciones56(0);
    //setconfirmaciones250(0);
    setconfirmaciones43114(0);

    setRed(0);
    setToken('');
    setthetype(0);
    setamount();
    setmemo();
    setdecimalstoken(0);


    setclosetrade(false);
    setverdatos('');
    setlisten(0);
    setconfirmed(0);
  
    setpolygonflag(0);
    setbnbflag(0);
    //setfantomflag(0);
    setavalancheflag(0);

    seteid137(0);
    setesender137('');
    setehash137('');
    //seteblock137(0);
  
    seteid56(0);
    setesender56('');
    setehash56('');
    //seteblock56(0);
  
    //seteid250(0);
    //setesender250('');
    //setehash250('');
    //seteblock250(0);


    seteid43114(0);
    setesender43114('');
    setehash43114('');
    //seteblock43114(0);



    setstatus137('');
    setidstatus137(1);

    setstatus56('');
    setidstatus56(1);

    //setstatus250('');
    //setidstatus250(1);


    setstatus43114('');
    setidstatus43114(1);


    sub1.unsubscribe(function(error, success){
    });

   sub2.unsubscribe(function(error, success){
   });

  
  sub4.unsubscribe(function(error, success){
  });


  }
  



function setWallet1(e) {


  setmensaje("");
  setmensaje1("");


setWallet(e);
setQr('');
setdatalink('');

setlisten(0);
setconfirmed(0);

//provider137.eth.clearSubscriptions();
//provider56.eth.clearSubscriptions();

}

function setRed1(e) {

  setmensaje("");
  setmensaje1("");



  setRed(e);
  setQr('');
  setToken('');
  setdatalink('');

  setlisten(0);
  setconfirmed(0);

  //provider137.eth.clearSubscriptions();
  //provider56.eth.clearSubscriptions();

  }

  
function setthetype1(e) {

  setmensaje("");
  setmensaje1("");


  setthetype(e);
  setQr('');
  setdatalink('');
  
  setlisten(0);
  setconfirmed(0);

  //provider137.eth.clearSubscriptions();
  //provider56.eth.clearSubscriptions();

}


function setmemo1(e) {
  
  
  setmensaje("");
  setmensaje1("");
  
  setmemo(e);
  setQr('');
  setdatalink('');

  setlisten(0);
  setconfirmed(0);

  //provider137.eth.clearSubscriptions();
  //provider56.eth.clearSubscriptions();


}



function setamount1(e) {
  
  setmensaje("");
  setmensaje1("");
  
  
  setamount(e);
  setQr('');
  setdatalink('');

  setlisten(0);
  setconfirmed(0);

  //provider137.eth.clearSubscriptions();
  //provider56.eth.clearSubscriptions();


}

  function setToken1(e) {

    setmensaje("");
    setmensaje1("");

    var thetoken = e;
    setToken(e);
    setQr('');
    setdatalink('');
    
    setlisten(0);
    setconfirmed(0);

  //provider137.eth.clearSubscriptions();
  //provider56.eth.clearSubscriptions();

    var marketusdt = '0xc2132D05D31c914a87C6611C10748AEb04B58e8F';
    var marketusdc = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174';
    
    var marketbusd = '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56';
    var marketusdtbnb = '0x55d398326f99059fF775485246999027B3197955';
  
  //  var marketusdcfantom = '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75';

    var marketusdtava = '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7';
    var marketusdcava = '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E';


    if (thetoken.toLowerCase().localeCompare((marketusdt).toLowerCase()) == 0) {
      setdecimalstoken(6);
    }

    if (thetoken.toLowerCase().localeCompare((marketusdc).toLowerCase()) == 0) {
      setdecimalstoken(6);
    }

    if (thetoken.toLowerCase().localeCompare((marketbusd).toLowerCase()) == 0) {
      setdecimalstoken(18);
    }


    if (thetoken.toLowerCase().localeCompare((marketusdtbnb).toLowerCase()) == 0) {
      setdecimalstoken(18);
    }


  //  if (thetoken.toLowerCase().localeCompare((marketusdcfantom).toLowerCase()) == 0) {
  //    setdecimalstoken(6);
  //  }


    if (thetoken.toLowerCase().localeCompare((marketusdtava).toLowerCase()) == 0) {
      setdecimalstoken(6);
    }

    if (thetoken.toLowerCase().localeCompare((marketusdcava).toLowerCase()) == 0) {
      setdecimalstoken(6);
    }





    }
  

function CopyLink() {

  navigator.clipboard.writeText(datalink + '&lang=' + lang);

}




  return (

    
<div className="Land">    
<header className="Land-header">






<div class="card-collect">

<div class="row">
<div class="col">

{(lang == 1) &&
  <h6 class="text text-start text-dark"><a href={'../?lang=' + lang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Cobrar</b></h6>}


{(lang == 2) &&
  <h6 class="text text-start text-dark"><a href={'../?lang=' + lang}><img src={logo} class="img-fluid" width="40" height="auto"></img></a> <b>Collect</b></h6>}








</div>


<div class="col">

{lang == 1 &&
  <h6 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">English</div></h6>}

{lang == 2 &&
  <h6 class="text text-end text-muted" onClick={SelectLanguage}><div class="pointer">Español</div></h6>}



</div>
</div>  

<p></p>


<div class="row">
<div class="col">


<div class="row">
<div class="cols-sm-4">

{lang == 1 &&
<span>Dirección de Cobro</span>}

{lang == 2 &&
<span>Collect Address</span>}


</div>

<div class="cols-sm-8">    

    
{lang == 1 &&
    <input type="text" class="form-control" disabled={verdatos} placeholder="Direccion para recibir pagos" value={wallet} onChange={e => setWallet1(e.target.value)} />}

{lang == 2 &&
    <input type="text" class="form-control" disabled={verdatos} placeholder="Address to receive payments" value={wallet} onChange={e => setWallet1(e.target.value)} />}


</div>
</div>


<p></p>






<div class="table-responsive table-borderless bg-transparent">
<table class="table">
<tbody>






<tr>
<td>

{lang == 1 &&
<span>Cadena</span>}

{lang == 2 &&
<span>Chain</span>}


</td>
<td>

<div class="text text-end"><select  class="form-control" disabled={verdatos} value={red} onChange={e => setRed1(e.target.value)}>

  {lang == 1 &&
    <option value="0" disabled selected>Cadena para recibir pagos</option>}
    
    {lang == 2 &&
    <option value="0" disabled selected>Chain to receive payments</option>}

    <option value="137">Polygon</option>
    <option value="56">BNB Smart Chain</option>



  </select></div>

</td>
</tr>







{red == 137 &&


<tr>
<td>

{lang == 1 &&
<span>Token</span>}

{lang == 2 &&
<span>Token</span>}


</td>
<td>


<div class="text text-end"><select class="form-control" disabled={verdatos} value={token} onChange={e => setToken1(e.target.value)}>

  {lang == 1 &&
    <option value="0" selected>Token para recibir pagos</option>}
    
    {lang == 2 &&
    <option value="0" selected>Token to receive payments</option>}
    
    <option value="0xc2132D05D31c914a87C6611C10748AEb04B58e8F">USDT</option>
    <option value="0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174">USDC</option>
  </select></div>
  
  </td>
  </tr>}



  {red == 43114 &&


<tr>
<td>

{lang == 1 &&
<span>Token</span>}

{lang == 2 &&
<span>Token</span>}


</td>
<td>


<div class="text text-end"><select class="form-control" disabled={verdatos} value={token} onChange={e => setToken1(e.target.value)}>

  {lang == 1 &&
    <option value="0" selected>Token para recibir pagos</option>}
    
    {lang == 2 &&
    <option value="0" selected>Token to receive payments</option>}
    
    <option value="0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7">USDT</option>
    <option value="0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E">USDC</option>
  </select></div>
  
  </td>
  </tr>}


















{red == 56 &&


<tr>
<td>

{lang == 1 &&
<span>Token</span>}

{lang == 2 &&
<span>Token</span>}


</td>
<td>

<div class="text text-end"><select class="form-control" disabled={verdatos} value={token} onChange={e => setToken1(e.target.value)}>

  {lang == 1 &&
    <option value="0" selected>Token para recibir pagos</option>}
    
    {lang == 2 &&
    <option value="0" selected>Token to receive payments</option>}
    
    <option value="0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56">BUSD</option>
    <option value="0x55d398326f99059fF775485246999027B3197955">USDT</option>
    
  
  </select></div>
  
  </td>
  </tr>}



  <tr>
<td>

{lang == 1 &&
<span>Tipo</span>}

{lang == 2 &&
<span>Type</span>}


</td>
<td>


  <div class="text text-end"><select class="form-control" disabled={verdatos} value={thetype} onChange={e => setthetype1(e.target.value)}>

  {lang == 1 &&
    <option value="0" disabled selected>Tipo de QR o LINK</option>}
    
    {lang == 2 &&
    <option value="0" disabled selected>QR or LINK Type</option>}

    {lang == 1 &&  
    <option value="1">Estatico</option>}
    
    {lang == 1 &&  
    <option value="2">Dinamico</option>}


    {lang == 2 &&  
    <option value="1">Static</option>}
    
    {lang == 2 &&  
    <option value="2">Dynamic</option>}



  </select></div>

</td>
</tr>


{thetype == 2 &&

<tr>
<td>
  
  {lang == 1 &&
  <span>Importe</span>}
  
  {lang == 2 &&
  <span>Amount</span>}
  
  
</td>
<td>


{lang == 1 &&

    <div class="text text-end"><input type="number" class="form-control" disabled={verdatos} placeholder="Monto a Cobrar" value={amount} onChange={e => setamount1(e.target.value)} /></div>}

{lang == 2 &&
   
    <div class="text text-end"><input type="number" class="form-control" disabled={verdatos} placeholder="Amount to Charge" value={amount} onChange={e => setamount1(e.target.value)} /></div>}




</td>
</tr>}



{thetype == 2 &&


<tr>
<td>

{lang == 1 &&
<span>Referencia #</span>}

{lang == 2 &&
<span>Reference #</span>}


</td>
<td>


{lang == 1 &&
    <div class="text text-end"><input type="number" class="form-control" disabled={verdatos} placeholder="Referencia transaccion" value={memo} onChange={e => setmemo1(e.target.value)} /></div>}

{lang == 2 &&
    <div class="text text-end"><input type="number" class="form-control" disabled={verdatos} placeholder="Transaction Reference" value={memo} onChange={e => setmemo1(e.target.value)} /></div>}




</td>
</tr>}




</tbody>
</table>
</div>


</div>
</div>



<p></p>



{mensaje.length > 0 &&
 

 <div class="alert alert-danger" role="alert">
 {mensaje}
 </div>}
 
 





<div class="row">
<div class="col">

{listen == 0 &&
<div class="text text-start"><button type="button" class="btn btn-sm btn-primary" onClick={GenerateQRCode}>QR</button></div>}

</div>

<div class="col">


{listen == 0 &&
<div class="text text-end"><button type="button" class="btn btn-sm btn-primary" onClick={GenerateLink}>LINK</button></div>}

</div>
</div>


<p></p>


{(datalink.length > 0 && !qr) &&
  <div class="text text-center">



{(lang == 1 && listen == 1) &&  


<div class="alert alert-success" role="alert">
<div class="spinner-border spinner-border-sm text-dark" role="status"></div> <b>Esperando transacción...</b>
</div>}




  

{(lang == 2 && listen == 1) &&


<div class="alert alert-success" role="alert">
<div class="spinner-border spinner-border-sm text-dark" role="status"></div> <b>Waiting transaction...</b>
</div>}


<p class="text text-center">




<div class="row">
<div class="col">


{(lang == 1 && listen == 0)&&
<div class="text text-start"><button type="button" class="btn btn-sm btn-primary" onClick={CopyLink}>Copiar Link</button></div>}

{(lang == 2 && listen == 0)&&
<div class="text text-start"><button type="button" class="btn btn-sm btn-primary" onClick={CopyLink}>Copy Link</button></div>}





</div>
<div class="col">


{(listen == 0 && thetype == 2 && lang == 1) &&
<div class="text text-end"><button type="button" class="btn btn-sm btn-primary" onClick={listenEvents}>COBRAR</button></div>}

{(listen == 0  && thetype == 2 && lang == 2) &&
<div class="text text-end"><button type="button" class="btn btn-sm btn-primary" onClick={listenEvents}>COLLECT</button></div>}




</div>
</div>


<p></p>


{(listen == 1 && thetype == 2 && lang == 1) &&
<button type="button" class="btn-pay btn-sm btn-primary" onClick={stopEvents}>Salir</button>}

{(listen == 1 && thetype == 2 && lang == 2) &&
<button type="button" class="btn-pay btn-sm btn-primary" onClick={stopEvents}>Exit</button>}


</p>


<p></p>



</div>}




{qr && <>
  <div class="text text-center">



  
{(lang == 1 && listen == 1) &&  


<div class="alert alert-success" role="alert">
<div class="spinner-border spinner-border-sm text-dark" role="status"></div> <b>Esperando transacción...</b>
</div>}





  

{(lang == 2 && listen == 1) &&

<div class="alert alert-success" role="alert">
<div class="spinner-border spinner-border-sm text-dark" role="status"></div> <b>Waiting transaction...</b>
</div>}













<div>



<div class="row">
<div class="col">



{(listen == 0 && lang == 1) &&
<div class="text text-start"><a href={qr} download="qrcode.png"><button type="button" class="btn btn-sm btn-primary">Descargar</button></a></div>}

{(listen == 0 && lang == 2) &&
  <div class="text text-start"><a href={qr} download="qrcode.png"><button type="button" class="btn btn-sm btn-primary">Download</button></a></div>}

</div>
<div class="col">


{(listen == 0 && thetype == 2 && lang == 1) &&
<div class="text text-end"><button type="button" class="btn btn-sm btn-primary" onClick={listenEvents}>COBRAR</button></div>}

{(listen == 0  && thetype == 2 && lang == 2) &&
<div class="text text-end"><button type="button" class="btn btn-sm btn-primary" onClick={listenEvents}>COLLECT</button></div>}


</div>
</div>





</div>



<p></p>




{(listen == 1 && thetype == 2 && lang == 1) &&
<button type="button" class="btn-pay btn-sm btn-primary" onClick={stopEvents}>Salir</button>}

{(listen == 1 && thetype == 2 && lang == 2) &&
<button type="button" class="btn-pay btn-sm btn-primary" onClick={stopEvents}>Exit</button>}





<p></p>



<br></br>


<img src={qr} alt="qr-code" width="240" />


</div>
</>}




















{(polygonflag == 1 && confirmed == 1 && listen == 2) &&

<div class="table-responsive-sm table-borderless bg-transparent">
<table class="table">
<tbody>
  


<tr>
<td>

{lang == 1 &&
<div class="text text-start text-info"><b>PAGO</b></div>}

{lang == 2 &&
<div class="text text-start text-info"><b>PAYMENT</b></div>}

</td>
<td>

<h6 class="text text-end"><img src={update} class="img-fluid-update" width="25" height="auto" onClick={updateStatus}></img></h6>

</td>
</tr>





<tr>
<td>

{lang == 1 &&
<div class="text text-start">Desde</div>}

{lang == 2 &&
<div class="text text-start">From</div>}

</td>
<td>

<div class="text text-end">Polygon</div>

</td>
</tr>











<tr>
<td>


<div class="text text-start">ID</div>


</td>
<td>

<div class="text text-end">{eid137}</div>

</td>
</tr>



<tr>
<td>

{lang == 1 &&
<div class="text text-start">Remitente</div>}

{lang == 2 &&
<div class="text text-start">Sender</div>}

</td>
<td>

<div class="text text-end">{esender137.substr(0,6)}...{esender137.substr(-4)}</div>

</td>
</tr>

















<tr>
<td>



<div class="text text-start">Status</div>

</td>
<td>

{idstatus137 == 1 &&
<div class="text text-end text-warning"><div class="spinner-border spinner-border-sm text-dark" role="status"></div> {status137}</div>}

{idstatus137 == 2 &&
<div class="text text-end text-danger">{status137}</div>}

{idstatus137 == 3 &&
<div class="text text-end text-success">{status137}</div>}



</td>
</tr>


<tr>
<td>

{lang == 1 &&
<div class="text text-start">Confirmaciones</div>}

{lang == 2 &&
<div class="text text-start">Confirmations</div>}

</td>
<td>

<div class="text text-end">{confirmaciones137}/48</div>

</td>
</tr>





</tbody>
</table>
</div>}





{(avalancheflag == 1 && confirmed == 1 && listen == 2) &&

<div class="table-responsive-sm table-borderless bg-transparent">
<table class="table">
<tbody>
  


<tr>
<td>

{lang == 1 &&
<div class="text text-start text-info"><b>PAGO</b></div>}

{lang == 2 &&
<div class="text text-start text-info"><b>PAYMENT</b></div>}

</td>
<td>

<h6 class="text text-end"><img src={update} class="img-fluid-update" width="25" height="auto" onClick={updateStatus}></img></h6>

</td>
</tr>





<tr>
<td>

{lang == 1 &&
<div class="text text-start">Desde</div>}

{lang == 2 &&
<div class="text text-start">From</div>}

</td>
<td>

<div class="text text-end">Avalanche</div>

</td>
</tr>











<tr>
<td>


<div class="text text-start">ID</div>


</td>
<td>

<div class="text text-end">{eid43114}</div>

</td>
</tr>



<tr>
<td>

{lang == 1 &&
<div class="text text-start">Remitente</div>}

{lang == 2 &&
<div class="text text-start">Sender</div>}

</td>
<td>

<div class="text text-end">{esender43114.substr(0,6)}...{esender43114.substr(-4)}</div>

</td>
</tr>



<tr>
<td>



<div class="text text-start">Status</div>

</td>
<td>

{idstatus43114 == 1 &&
<div class="text text-end text-warning"><div class="spinner-border spinner-border-sm text-dark" role="status"></div> {status43114}</div>}

{idstatus43114 == 2 &&
<div class="text text-end text-danger">{status43114}</div>}

{idstatus43114 == 3 &&
<div class="text text-end text-success">{status43114}</div>}



</td>
</tr>


<tr>
<td>

{lang == 1 &&
<div class="text text-start">Confirmaciones</div>}

{lang == 2 &&
<div class="text text-start">Confirmations</div>}

</td>
<td>

<div class="text text-end">{confirmaciones43114}/12</div>

</td>
</tr>





</tbody>
</table>
</div>}






{(bnbflag == 1 && confirmed == 1 && listen == 2) &&

<div class="table-responsive-sm table-borderless bg-transparent">
<table class="table">
<tbody>
  


<tr>
<td>

{lang == 1 &&
<div class="text text-start text-info"><b>PAGO</b></div>}

{lang == 2 &&
<div class="text text-start text-info"><b>PAYMENT</b></div>}

</td>
<td>

<div class="text text-end"><img src={update} class="img-fluid-update" width="25" height="auto" onClick={updateStatus}></img></div>

</td>
</tr>








<tr>
<td>

{lang == 1 &&
<div class="text text-start">Desde</div>}

{lang == 2 &&
<div class="text text-start">From</div>}

</td>
<td>

<div class="text text-end">BNB Smart Chain</div>

</td>
</tr>















<tr>
<td>


<div class="text text-start">ID</div>


</td>
<td>

<div class="text text-end">{eid56}</div>

</td>
</tr>



<tr>
<td>

{lang == 1 &&
<div class="text text-start">Remitente</div>}

{lang == 2 &&
<div class="text text-start">Sender</div>}

</td>
<td>

<div class="text text-end">{esender56.substr(0,6)}...{esender56.substr(-4)}</div>

</td>
</tr>













<tr>
<td>





<div class="text text-start">Status</div>

</td>
<td>


{idstatus56 == 1 &&
<div class="text text-end text-warning"><div class="spinner-border spinner-border-sm text-dark" role="status"></div> {status56}</div>}

{idstatus56 == 2 &&
<div class="text text-end text-danger">{status56}</div>}

{idstatus56 == 3 &&
<div class="text text-end text-success">{status56}</div>}



</td>
</tr>


<tr>
<td>

{lang == 1 &&
<div class="text text-start">Confirmaciones</div>}

{lang == 2 &&
<div class="text text-start">Confirmations</div>}

</td>
<td>

<div class="text text-end">{confirmaciones56}/20</div>

</td>
</tr>





</tbody>
</table>
</div>}



<p class="text text-center">


{(closetrade && lang == 1) &&

<button type="button" class="btn-pay btn-sm btn-success" onClick={closePay}>Salir</button>}

{(closetrade && lang == 2) &&

<button type="button" class="btn-pay btn-sm btn-success" onClick={closePay}>Exit</button>}

</p>







</div>

</header>
</div>

);

}


export default Qrcode;