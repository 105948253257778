import Web3 from 'web3';
import React from 'react';

//import { ethers } from "ethers";
import AbiGateway from "./contracts/Gateway.json";


//import Qrcode from './Qrcode';
//import Transactions from './Transactions';


import { useState, useEffect } from "react";
import './Land.css';

import ethereum from './ethereum.svg';

import matic from './matic.svg';


import tether from './usdt.svg';

import usdc from './usdc.svg';


import binance from './binance.svg';

import optimism from './optimism.png';
import arbitrum from './arbitrum.png';
import avalanche from './avalanche.png';
//import fantom from './fantom.png';
//import pw3 from './WD12.png';
//import pw3 from './WEBTHREE1.png';
import pw3 from './pw3bis.png';

import recarga from './lockfunds.png';
import tarjeta from './nocards.png';
import porcentaje from './zero.png';
import videoweb3 from './videoweb3.gif';
import videoweb3eng from './videoweb3.gif';
import checkbox from './checkbox.png';
import bolsa from './bolsa2.png';
import gitbook from './gitbook.png';
import twitter from './twitter.png';
import Web3tizate from './Web3tizate.png';
import liberate from './LIBERATE2.png';
import pagala from './PAGALA.png';
import molde from './molde1.png';
import stargate from './stargate.png';
import pancakeswap from './pancakeswap.png';
import uniswap from './uniswap1.png';
import logo from './logo.png';
import mock from './mock2.png';
import ok from './SIMPLE.gif';
import busd from './busd.png';
import movi from './moveimage.gif';
import learn from './LEARN.gif';
import dashboard from './DASHBOARD.gif';

import dapp from './DAPPENG.gif';
import pos from './POSENG.gif';

import dapp2 from './DAPPESP.gif';
import pos2 from './POSESP.gif';
import fantom from './fantom.svg';

import easy from './easy.svg';
import safe from './safe.svg';
import reliable from './reliable.svg';
import flexible from './flexible.svg';

import decentralized from './decentralized.svg';
import fast from './fast.svg';
import immutable from './immutable.svg';
import agnostic from './agnostic.svg';

import {
Nav,
   Input,
} from 'reactstrap';




function Land() {


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const thelang = urlParams.get("lang");




  const web3 = new Web3('https://polygon-rpc.com/');  
  const gatewayaddress = '0xb8FD32082b2AF3Cf602bD53994E1632dbf9843cC'
  const contractgateway = new web3.eth.Contract(AbiGateway, gatewayaddress);


  const [storeaddress, setstoreaddress] = useState();
  const [walletstore, setWalletstore] = useState();
  const [mensaje, setmensaje] = useState("");
  const [lang, setlang] = useState(1);
  

    useEffect(() => {
      

        if (thelang == 1) {
            setlang(1);
          }
      
          if (thelang == 2) {
            setlang(2);
          }
      




      //async function fetchdata() {
        
     
     //}
     //fetchdata();

       
    
     
  },[]);



    

function SelectLanguage() {
  if (lang == 1) {
    setlang(2);
  }

  if (lang == 2) {
    setlang(1);
  }

}











  return (

    
<div className="Land">    

<div class="container">

<p></p>


<nav class="navbar navbar-expand-sm bg-white navbar-dark fixed-top">
  <div class="container-fluid">
  <a class="navbar-brand"><img src={pw3} class="img-fluid" width="70" height="auto"></img></a>
    <button class="navbar-toggler bg-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">

        <li class="nav-item">
        {lang == 1 &&
        <a class="nav-link" href={'https://pay.web3debit.shop?lang=' + lang}>Paga</a>}

        {lang == 2 &&
        <a class="nav-link" href={'https://pay.web3debit.shop?lang=' + lang}>Pay</a>}
        </li>






        <li class="nav-item">
        {lang == 1 &&
        <a class="nav-link" href="./collect?lang=1">Cobra</a>}

        {lang == 2 &&
        <a class="nav-link" href="./collect?lang=2">Collect</a>}
        </li>




        




<li class="nav-item">
{lang == 1 &&
<a class="nav-link" href={'./transactions?lang=' + lang}>Tablero</a>}

{lang == 2 &&
<a class="nav-link" href={'./transactions?lang=' + lang}>Dashboard</a>}
</li>


<li class="nav-item">
{lang == 1 &&
<a class="nav-link" href="https://docs.web3debit.shop/v/spanish" target="_blank">Docs</a>}

{lang == 2 &&
<a class="nav-link" href="https://docs.web3debit.shop/v/english" target="_blank">Docs</a>}
</li>



<li class="nav-item">
{lang == 1 &&
<a class="nav-link" onClick={SelectLanguage}><div class="pointer">English</div></a>}

{lang == 2 &&
<a class="nav-link" onClick={SelectLanguage}><div class="pointer">Español</div></a>}
</li>



      </ul>
    </div>
  </div>
</nav>














<p></p>
<br></br>
<br></br>

<br></br>
<br></br>

<br></br>
<br></br>




<div class="row row-cols-1 row-cols-lg-2 g-1">
  <div class="col">
    <div class="card bg-transparent h-100">
    <div class="card-body bg-transparent">




    <p class="text11 text-start"><b>Web3Debit</b></p>

    {lang == 1 &&
    <p class="text text-start text-secondary"><h5>Swap, Bridge y Pago en un click</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Swap, Bridge and Pay in one click</h5></p>}






{lang == 1 &&
<p class="text1 text-start"><h1><b>Paga y Cobra con Cripto desde tu Wallet</b></h1></p>}

{lang == 2 &&
<p class="text1 text-start"><h1><b>Pay and Collect with Crypto from your Wallet</b></h1></p>}



{lang == 1 &&
<p class="text4 text-start"><h4>Olvídese de terminales POS, tarjetas de débito y bancos. Di hola a la forma más segura y rápida de realizar y cobrar pagos.</h4></p>}

{lang == 2 &&
<p class="text4 text-start"><h4>Forget POS terminals, debit cards and banks. Say hello to the safest and fastest way to make and collect payments.</h4></p>}


<br></br>

{lang == 1 &&
<div class="text text-start">


<a href={'https://pay.web3debit.shop?lang=' + lang}><button type="button" class="btn btn-primary btn-lg">Paga</button></a>

<span> </span>

<a href="./collect?lang=1"><button type="button" class="btn btn-primary btn-lg">Cobra</button></a>
</div>}


{lang == 2 &&
  <div class="text text-start">  
  
  <a href={'https://pay.web3debit.shop?lang=' + lang}><button type="button" class="btn btn-primary btn-lg">Pay</button></a>

<span> </span>

<a href="./collect?lang=2"><button type="button" class="btn btn-primary btn-lg">Collect</button></a>
</div>}


</div>
</div>
</div>

<div class="col">

<div class="card bg-transparent h-100">
<div class="card-body bg-transparent">

<div class="text text-center"><img src={movi} class="img-fluid" width="400" height="auto"></img></div>

</div>
</div>
</div>
</div>





<p></p>

<br></br>
<br></br>
<br></br>
<br></br>
<br></br>






<div class="card-info bg-light h-100">
<div class="card-body bg-transparent">





{lang == 1 &&
<p class="text1 text-center text-dark"><h1><b>Pagos Descentralizados Cross Chain</b></h1></p>}

{lang == 2 &&
<p class="text1 text-center text-dark"><h1><b>Cross Chain Decentralized Payments</b></h1></p>}




<p class="text text-center">
  
  <img src={matic} class="img-fluid" width="30" height="30"></img>
  
  <span> </span>

  <img src={binance} class="img-fluid" width="30" height="30"></img>

<span> </span>


  <img src={avalanche} class="img-fluid" width="30" height="30"></img>
  
  

</p>









{lang == 1 &&
<p class="text4 text-center"><h4>Selecciona cualquier Token y Cadena compatible desde donde deseas pagar.</h4></p>}




{lang == 2 &&
<p class="text4 text-center"><h4>Select any supported Token and Chain from where you want to Pay.</h4></p>}




{lang == 1 &&
<p class="text4 text-center text-secondary"><h4>Paga sin Comisiones en un click</h4></p>}




{lang == 2 &&
<p class="text4 text-center text-secondary"><h4>Pay with No Fees in one click</h4></p>}









</div>
</div>


<p></p>
<br></br>



<div class="row row-cols-1 row-cols-md-2 g-4">
  <div class="col">
    <div class="card-info h-100">

      
        <h5 class="card-title"></h5>

        <p class="text text-start"><img src={easy} class="img-fluid" width="50" height="auto"></img></p>  
    

        {lang == 1 &&
    <p class="text text-start text-dark"><h3><b>Simple</b></h3></p>}

{lang == 2 &&
    <p class="text text-start text-dark"><h3><b>Simple</b></h3></p>}




<p></p>


{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Paga desde tu Wallet preferida sin instalar ninguna aplicación. Tienes 34 wallets compatibles para elegir.</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Pay from your preferred Wallet without installing any application. You have 34 compatible wallets to choose from.</h5></p>}




      </div>
    </div>
  
  
  
  <div class="col">
    <div class="card-info h-100">

      
        <h5 class="card-title"></h5>
        

        <p class="text text-start"><img src={safe} class="img-fluid" width="50" height="auto"></img></p>  

        {lang == 1 &&
    <p class="text text-start"><h3><b>Seguro</b></h3></p>}

{lang == 2 &&
    <p class="text text-start"><h3><b>Safe</b></h3></p>}




<p></p>




{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Tus cripto siempre están bajo tu custodia en tu wallet preferida. No tienes que depositarlas ni cederlas a terceras partes.</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Your crypto is always in your custody in your preferred wallet. You do not have to deposit or assign them to third parties.</h5></p>}


        
        
        
      </div>
    </div>
  



  <div class="col">
    <div class="card-info h-100">
      
      
        <h5 class="card-title"></h5>
        
      
        <p class="text text-start"><img src={reliable} class="img-fluid" width="50" height="auto"></img></p>  


        {lang == 1 &&
    <p class="text text-start"><h3><b>Confiable</b></h3></p>}

{lang == 2 &&
    <p class="text text-start"><h3><b>Reliable</b></h3></p>}




<p></p>




{lang == 1 &&
    <p class="text text-start text-secondary"><h5>
      Swaps proporcionados por +100 Exchanges Descentralizados (DEX's) con una mayor profundidad de liquidez y el menor deslizamiento resultando en un tipo de cambio transparente al momento del pago. 
      Conocé exactamente cuanto vas a pagar.</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Swaps provided by +100 Decentralized Exchanges (DEX's) with the deepest liquidity and lowest slippage for a transparent exchange rate at the time of payment. 
      Know exactly how much you will pay.</h5></p>}


      
      
      
      </div>
    </div>
  


  <div class="col">
    <div class="card-info h-100">
      
      
        <h5 class="card-title"></h5>
      
        
        <p class="text text-start"><img src={flexible} class="img-fluid" width="50" height="auto"></img></p>  


        {lang == 1 &&
    <p class="text text-start"><h3><b>Flexible</b></h3></p>}

{lang == 2 &&
    <p class="text text-start"><h3><b>Flexible</b></h3></p>}




<p></p>




{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Elige el token y cadena compatible desde donde quieres pagar sin importar el token y cadena aceptada por el comercio. 
      Intercambie activos nativos 1:1 entre cadenas, accediendo a pools de liquidez unificados.
      </h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Choose the compatible token and chain from where you want to pay regardless of the token and chain accepted by the merchant. 
      Swap 1:1 native assets cross-chain, accessing unified liquidity pools.
      </h5></p>}






      </div>
    </div>
  </div>






<p></p>





<p></p>
<p></p>
<br></br>
<br></br>
<br></br>



<div class="card-info bg-light h-100">
<div class="card-body bg-transparent">







{lang == 1 &&
<p class="text1 text-center text-dark"><h1><b>Cobra con Cripto</b></h1></p>}

{lang == 2 &&
<p class="text1 text-center text-dark"><h1><b>Collect with Crypto</b></h1></p>}


<p class="text text-center"> 

<img src={tether} class="img-fluid" width="30" height="30"></img>
  
  <span> </span>

  <img src={usdc} class="img-fluid" width="30" height="30"></img>

<span> </span>

<img src={busd} class="img-fluid" width="30" height="30"></img>
  
</p>





{lang == 1 &&
<p class="text4 text-center"><h4>Comienza a Recibir Pagos en Cripto en menos de 30 segundos.</h4></p>}


{lang == 2 &&
<p class="text4 text-center"><h4>Start Getting Paid with Crypto in less than 30 seconds.</h4></p>}



{lang == 1 &&
<p class="text4 text-center text-secondary"><h4>La comisión más baja: 0,5 %</h4></p>}




{lang == 2 &&
<p class="text4 text-center text-secondary"><h4>The Lowest Fee: 0.5 %</h4></p>}


{lang == 1 &&
<p class="text4 text-center text-dark"><h6>Disfruta de 0,00 % de comisión hasta Marzo 31, 2024</h6></p>}




{lang == 2 &&
<p class="text4 text-center text-dark"><h6>Enjoy 0,00 % fee till March 31, 2024</h6></p>}








</div>
</div>



<p></p>

<br></br>



<div class="row row-cols-1 row-cols-md-2 g-4">
  
  <div class="col">
    <div class="card-info h-100">
  
      
        <h5 class="card-title"></h5>


        <p class="text text-start"><img src={fast} class="img-fluid" width="50" height="auto"></img></p>  

        {lang == 1 &&
    <p class="text text-start"><h3><b>Fácil</b></h3></p>}

{lang == 2 &&
    <p class="text text-start"><h3><b>Easy</b></h3></p>}




<p></p>




{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Uso gratuito, sin costos mensuales ni contratos. No require permisos, instalación ni registración. 
      Punto de Venta online para cobrar con código QR y LINK de pago.</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Free to use, no monthly costs or contracts. Permission-less. No installation and registration required. 
      Online Point of Sale to collect with QR code and payment LINK.</h5></p>}

  
      </div>
    </div>
  
  


  <div class="col">
    <div class="card-info h-100">
  
      
        <h5 class="card-title"></h5>


        <p class="text text-start"><img src={decentralized} class="img-fluid" width="50" height="auto"></img></p>  
        

        {lang == 1 &&
    <p class="text text-start"><h3><b>Descentralizado</b></h3></p>}

{lang == 2 &&
    <p class="text text-start"><h3><b>Decentralized</b></h3></p>}




<p></p>




{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Acreditación Instantánea sin custodia. Sin cuenta bancaria.</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Credited Instantly non-custodial. No bank account required.</h5></p>}

      
      </div>
    </div>
  
  


  <div class="col">
   <div class="card-info h-100">
  
      
        <h5 class="card-title"></h5>


        <p class="text text-start"><img src={immutable} class="img-fluid" width="50" height="auto"></img></p>  



        {lang == 1 &&
    <p class="text text-start"><h3><b>Inmutable</b></h3></p>}

{lang == 2 &&
    <p class="text text-start"><h3><b>Immutable</b></h3></p>}




<p></p>




{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Finalidad On Chain y Cross Chain Instantánea Garantizada. Sin contracargos.</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>On Chain and Cross Chain Instant Guaranteed Finality. No chargebacks.</h5></p>}

      
      </div>
    </div>
  
  


  <div class="col">
   <div class="card-info h-100">
  
      
        <h5 class="card-title"></h5>

        <p class="text text-start"><img src={agnostic} class="img-fluid" width="50" height="auto"></img></p>  


        {lang == 1 &&
    <p class="text text-start"><h3><b>Agnóstico</b></h3></p>}

{lang == 2 &&
    <p class="text text-start"><h3><b>Agnostic</b></h3></p>}




<p></p>




{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Acepta pagos hacia y desde cualquier token y cadena compatibles.</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Accept payments to and from any supported token and chain.</h5></p>}



      
      </div>
    </div>
  



  <div class="col">
   <div class="card-info h-100">
  
      
        <h5 class="card-title"></h5>
        


        


        




{lang == 1 &&
<p class="text text-start"><h3><b>Tablero</b></h3></p>}

{lang == 2 &&
<p class="text text-start"><h3><b>Dashboard</b></h3></p>}



<p></p>


{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Consulta de pagos.</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Check payments.</h5></p>}


{lang == 1 &&
<div class="text text-start">
<a href={'./transactions?lang=' + lang}><button type="button" class="btn btn-primary btn-lg">Tablero</button></a>
</div>}


{lang == 2 &&
<div class="text text-start">
<a href={'./transactions?lang=' + lang}><button type="button" class="btn btn-primary btn-lg">Dashboard</button></a>
</div>}






      
      </div>
    </div>
  

  <div class="col">
   <div class="card-info h-100">
  
      
        <h5 class="card-title"></h5>
        




{lang == 1 &&
<p class="text text-start"><h3><b>Documentos</b></h3></p>}

{lang == 2 &&
<p class="text text-start"><h3><b>Documents</b></h3></p>}





<p></p>

{lang == 1 &&
    <p class="text text-start text-secondary"><h5>Aprende más...</h5></p>}

{lang == 2 &&
    <p class="text text-start text-secondary"><h5>Learn more...</h5></p>}

{lang == 1 &&
<div class="text text-start">
<a href="https://docs.web3debit.shop/v/spanish" target="_blank"><button type="button" class="btn btn-primary btn-lg">Docs</button></a>
</div>}


{lang == 2 &&
<div class="text text-start">
<a href="https://docs.web3debit.shop/v/english" target="_blank"><button type="button" class="btn btn-primary btn-lg">Docs</button></a>
</div>}





      
      </div>
    </div>
  </div>



<p></p>
<br></br>
<br></br>
<br></br>





<p></p>
<br></br>
<br></br>



<p></p>


















<p></p>



<p></p>

</div>

<div class="header-foot">


<p class="text text-center"><img src={pw3} class="img-fluid" width="80" height="auto"></img></p>

<p class="text12 text-start"><b>Web3Debit</b></p>



{lang == 1 &&
<p class="text text-center text-secondary">Web3Debit.shop - un Protocolo Blockchain</p>}



{lang == 2 &&
<p class="text text-center text-secondary">Web3Debit.shop - a Blockchain Protocol</p>}


<p class="text text-center text-secondary">Powered by</p>

<p class="text text-center text-secondary">
  
  0x.org | Stargate.finance
   
  
</p>




    <p class="text text-center text-secondary">
    



    {lang == 1 &&
    <a href="https://docs.web3debit.shop/v/spanish" target="_blank">Docs</a>}
    
    {lang == 2 &&
    <a href="https://docs.web3debit.shop/v/english/" target="_blank">Docs</a>}


    <span> | </span>

    <a href="https://twitter.com/web3debit" target="_blank">Twitter</a>
    
    <span> | </span>

    <a href="https://discord.com/invite/c9gtGnR2Yt" target="_blank">Discord</a>
    
    <span> | </span>

    {lang == 1 &&
    <a href="https://docs.web3debit.shop/inversores" target="_blank">Inversores</a>}
  
    {lang == 2 &&
    <a href="https://docs.web3debit.shop/v/english/investors" target="_blank">Investors</a>}

    

    </p>


<p></p>




</div>






</div>
);

}


export default Land;