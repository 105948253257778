import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Land from './Land';
import Transactions from './Transactions';
import QR from './Qrcode';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from './reportWebVitals';


export default function App() {
  return (
<BrowserRouter>
      
      
      <Routes>
        <Route path="/">
        <Route index element={<Land />} />
        <Route path="transactions" element={<Transactions />} />
        <Route path="collect" element={<QR />} /></Route>


        </Routes>    
      
      
    </BrowserRouter>
  );
}



ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
