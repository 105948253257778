import Web3 from 'web3';
import React from 'react';

import { ethers } from "ethers";
import AbiRouter from "./contracts/Router.json";
import AbiRouterV2 from "./contracts/RouterV2.json";
import AbiRouter0x from "./contracts/Router0x.json";

import { useState, useEffect } from "react";
import './Land.css';

import ethereum from './ethereum.svg';

import matic from './matic.svg';


import tether from './usdt.svg';

import usdc from './usdc.svg';


import binance from './binance.svg';

import optimism from './optimism.png';
import arbitrum from './arbitrum.png';
import avalanche from './avalanche.png';
import fantom from './fantom.png';
//import pw3 from './WEBTHREE1.png';
import pw3 from './pw3bis.png';


import recarga from './lockfunds.png';
import tarjeta from './nocards.png';
import porcentaje from './zero.png';
import videoweb3 from './web3debitimage.jpg';
import checkbox from './checkbox.png';
import bolsa from './bolsa2.png';
import gitbook from './gitbook.png';
import twitter from './twitter.png';
import logo from './logo.png';


import {
   Button,
   Input,
} from 'reactstrap';




function Transactions(arr) {


  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const lang = urlParams.get("lang");





    const WEB3_ROUTER_ADDRESS137 = '0x59E0c044e55Bce7d26397536b396567762c36E4F';
    const WEB3_ROUTER_ADDRESS56 = '0x846cE1068B61eCC408b84034A0d9d5D64933160C';
    const WEB3_ROUTER_ADDRESS43114 = '0x150aE9FE2f79FfA41c1BA00aaCAED52F02A004a1';


    const CHAIN137 = 'https://polygon-rpc.com/';
    const CHAIN56 = 'https://bscrpc.com';
    //const CHAIN250 = 'https://rpc.ftm.tools';
    const CHAIN43114 = 'https://avalanche.public-rpc.com';



  const [thewallet, setthewallet] = useState('');
  const [mensaje, setmensaje] = useState('');
  const [thelang, setthelang] = useState(1);
  const [transacciones, setTransacciones] = useState([]);
  const [spinner, setspinner] = useState(0);



    useEffect(() => {

      
      if (lang == 1) {
        setthelang(1);
      }
  
      if (lang == 2) {
        setthelang(2);
      }
    
     
  },[]);




  function getEventsTo() {

    if (Web3.utils.isAddress(thewallet) == true) {
      getEventsChains(1);
    
    } else {

      if (thelang == 1) {
        setmensaje("Ingrese una dirección válida");
        }  
  
        if (thelang == 2) {
          setmensaje("Enter a valid address");
        }

    }  

  }


  function getEventsFrom() {

    if (Web3.utils.isAddress(thewallet) == true) {
      getEventsChains(2);
    
    } else {

      if (thelang == 1) {
        setmensaje("Ingrese una dirección válida");
        }  
  
        if (thelang == 2) {
          setmensaje("Enter a valid address");
        }

    }  

  }



  async function getEventsStargate(therpc, therouter, thetype, thewallet, thedestchain, thesourcechain, theid) {

    var crosschained = 0;	
    var thefee = 0;

    var provider = new Web3(therpc);  
    var contract = new provider.eth.Contract(AbiRouter0x, therouter);


    var latest_block = await provider.eth.getBlockNumber();
    var historical_block = latest_block - 1000;


if (thetype == 1) {	    
    var events = await contract.getPastEvents(
    'ReceivedFromStargate', {filter: {store: thewallet, id: theid},
    fromBlock: historical_block, toBlock: latest_block });
}


if (thetype == 2) {	    
    var events = await contract.getPastEvents(
    'ReceivedFromStargate', {filter: {sender: thewallet, id: theid},
    fromBlock: historical_block, toBlock: latest_block });
}



for (var i = 0; i < events.length; i++) {

	if (thesourcechain == events[i]['returnValues']['srcChain']) {
           
            var block = events[i]['blockNumber'];
            var isremoved = events[i]['removed'];
            thefee = events[i]['returnValues']['fee'];
		

	    if (block == null) {
		crosschained = 0;
	    
            } else {

		if (isremoved) {
                  crosschained = 3;		

		} else {

		    var confirmations = latest_block - block;

		    if (thedestchain == 56) {
		    if (confirmations <= 20) {
	            crosschained = 1;	
                    } else {
	            crosschained = 2;	
		    }	
		    }	


		    if (thedestchain == 137) {
		    if (confirmations <= 48) {
	            crosschained = 1;	
                    } else {
	            crosschained = 2;	
		    }	
		    }	


		    if (thedestchain == 43114) {
		    if (confirmations <= 12) {
	            crosschained = 1;	
                    } else {
	            crosschained = 2;	
		    }	
		    }	

	}
       }
      }
     }

     return [crosschained, String(thefee)];	   

} 






  async function getEventsChains(checktype) {

    setspinner(1);
    
        var provider = new Web3(CHAIN56);  
        var contract = new provider.eth.Contract(AbiRouter0x, WEB3_ROUTER_ADDRESS56);
    
    
        setmensaje("");
        setTransacciones([]);
        
        var thearray1 = [];
        var thearray2 = [];
        var tochain = 0;
        var decimals = 0;
        var nametoken = "";
        var confirmed = 0;	
        
    

          if (checktype == 1) {    
            //var latest_block = 27340915;
            var latest_block = await provider.eth.getBlockNumber();
            var historical_block = latest_block - 1000; // you can also change the value to 'latest' if you have a upgraded rpc
            
            var events = await contract.getPastEvents(
            'Routed', {filter: {store: thewallet},
             fromBlock: historical_block, toBlock: latest_block });
          }    
    
          if (checktype == 2) {    
            //var latest_block = 27340915 ;
            var latest_block = await provider.eth.getBlockNumber();
            var historical_block = latest_block - 1000; // you can also change the value to 'latest' if you have a upgraded rpc
            
            var events = await contract.getPastEvents(
            'Routed', {filter: {sender: thewallet},
             fromBlock: historical_block, toBlock: latest_block });
          }


    
      for (var i = 0; i < events.length; i++) {
              
              
              var confirmations = 0;
              var tokenout = events[i]['returnValues']['tokenOut'];
              var destchain = events[i]['returnValues']['destChain'];
              var isremoved = events[i]['removed'];
              var block = events[i]['blockNumber'];
              var thedate;
              var amountfixed; 
              var chained = 0;
              var feefixed = 0;


              if (block == null) {
                thedate = Date.now();
              } else {  
                var theblockinfo = await provider.eth.getBlock(block);
                thedate = theblockinfo.timestamp;
              }  
    
              thedate = new Date(thedate * 1000);
              var theformatdate = thedate.getFullYear() + '/' + (thedate.getMonth() + 1) + '/' + (thedate.getDate()) + ' ' + (thedate.getHours()) + ':' + 	            (thedate.getMinutes()) + ':' + (thedate.getSeconds());
              
              var status;

                if (block == null) {
              
                  confirmed = 1;

                  if (thelang == 1) {
                    status = "Pendiente...";
                  }
                  
                  if (thelang == 2) {
                    status = "Pending...";
                  }

                } else {

                  confirmations = latest_block - block;

                  if (confirmations <= 20) {
                    confirmed = 1;
                  
                    if (thelang == 1) {
                      status = "Confirmando...";
                    }
                    
                    if (thelang == 2) {
                      status = "Confirming...";
                    }
                  
                  } else {
                    confirmed = 2;
                    

                    if (thelang == 1) {
                      status = "Confirmada";
                    }
                    
                    if (thelang == 2) {
                      status = "Confirmed";
                    }
                  }

                }



                if (isremoved) {

                  confirmed = 3;

                  if (thelang == 1) {
                    status = "Removida";
                  }
                  
                  if (thelang == 2) {
                    status = "Removed";
                  }
                }







    
                if (destchain == 0) {
                  tochain = "BNB Chain";
                
                  if (tokenout == '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56') {
                    decimals = 18;
                    nametoken = "BUSD";
                    amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                  
                  }  
                  
                  if (tokenout == '0x55d398326f99059fF775485246999027B3197955') {
                    decimals = 18;
                    nametoken = "USDT";
                    amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                  }            
                
                chained = 4;
                feefixed = ethers.utils.formatUnits(events[i]['returnValues']['fee'], decimals); 
                
                }
    
                
                if (destchain == 109) {
                  tochain = "Polygon";
    
                  if (tokenout == '0xc2132D05D31c914a87C6611C10748AEb04B58e8F') {
                    decimals = 6;
                    nametoken = "USDT";
                    amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                  }  
                  
                  if (tokenout == '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174') {
                    decimals = 6;
                    nametoken = "USDC";
                    amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                  }
                
                  if (confirmed == 2 && confirmations > 20) {
                
                    var crossvalues = [];
                    crossvalues = await getEventsStargate(CHAIN137, WEB3_ROUTER_ADDRESS137, checktype, thewallet, 137, 56, events[i]['returnValues']['id']);
                
                    chained = crossvalues[0];
                    feefixed = ethers.utils.formatUnits(crossvalues[1], decimals); 
                
                  }

                  if (confirmed == 3) {
                    chained = 5;
                    feefixed = ethers.utils.formatUnits(feefixed, decimals); 
                  }


              }
    


                if (destchain == 106) {
                  tochain = "Avalanche";
    
                  if (tokenout == '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7') {
                    decimals = 6;
                    nametoken = "USDT";
                    amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                  }  
                  
                  if (tokenout == '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E') {
                    decimals = 6;
                    nametoken = "USDC";
                    amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                  }
                
                  if (confirmed == 2 && confirmations > 20) {
                  
                    var crossvalues = [];
                    crossvalues = await getEventsStargate(CHAIN43114, WEB3_ROUTER_ADDRESS43114, checktype, thewallet, 43114, 56, events[i]['returnValues']['id']);
                  
                    chained = crossvalues[0];
                    feefixed = ethers.utils.formatUnits(crossvalues[1], decimals); 
                  
                  }
                
                  if (confirmed == 3) {
                    chained = 5;
                    feefixed = ethers.utils.formatUnits(feefixed, decimals); 
                  }

                }




                
    
            
      
    
    
      thearray1.push({
      
        idtransaction: events[i]['returnValues']['id'],
              sender: events[i]['returnValues']['sender'],
              store: events[i]['returnValues']['store'],
              memo: events[i]['returnValues']['memo'],
              amountout: String(amountfixed),          
              hash: events[i]['transactionHash'],
              status: status,
              confirmations: confirmations,
    
              tokenout: tokenout,
        tochain: tochain,
        thedate: thedate,
        from: 'BNB Chain', 			
        nametoken: nametoken,
        theformatdate: theformatdate,
              decimals: decimals,
        confirmed: confirmed,
        chained: chained,
        feefixed: String(feefixed),
        thelink: 'https://bscscan.com/tx/'	  
      
        },);
    
      
           };		
    




////////////////////////////////////////////////////////////////////////
// AVALANCHE EVENTS


var provider = new Web3(CHAIN43114);  
var contract = new provider.eth.Contract(AbiRouter0x, WEB3_ROUTER_ADDRESS43114);



  if (checktype == 1) {    
    //var latest_block = 27340915;
    var latest_block = await provider.eth.getBlockNumber();
    var historical_block = latest_block - 1000; // you can also change the value to 'latest' if you have a upgraded rpc
    
    var events = await contract.getPastEvents(
    'Routed', {filter: {store: thewallet},
     fromBlock: historical_block, toBlock: latest_block });
  }    

  if (checktype == 2) {    
    //var latest_block = 27340915 ;
    var latest_block = await provider.eth.getBlockNumber();
    var historical_block = latest_block - 1000; // you can also change the value to 'latest' if you have a upgraded rpc
    
    var events = await contract.getPastEvents(
    'Routed', {filter: {sender: thewallet},
     fromBlock: historical_block, toBlock: latest_block });
  }



for (var i = 0; i < events.length; i++) {
      
      
      var confirmations = 0;
      var tokenout = events[i]['returnValues']['tokenOut'];
      var destchain = events[i]['returnValues']['destChain'];
      var isremoved = events[i]['removed'];
      var block = events[i]['blockNumber'];
      var thedate;
      var amountfixed; 
      var chained = 0;
      var feefixed = 0;


      if (block == null) {
        thedate = Date.now();
      } else {  
        var theblockinfo = await provider.eth.getBlock(block);
        thedate = theblockinfo.timestamp;
      }  

      thedate = new Date(thedate * 1000);
      var theformatdate = thedate.getFullYear() + '/' + (thedate.getMonth() + 1) + '/' + (thedate.getDate()) + ' ' + (thedate.getHours()) + ':' + 	            (thedate.getMinutes()) + ':' + (thedate.getSeconds());
      
      var status;

        if (block == null) {
      
          confirmed = 1;

          if (thelang == 1) {
            status = "Pendiente...";
          }
          
          if (thelang == 2) {
            status = "Pending...";
          }

        } else {

          confirmations = latest_block - block;

          if (confirmations <= 12) {
            confirmed = 1;
          
            if (thelang == 1) {
              status = "Confirmando...";
            }
            
            if (thelang == 2) {
              status = "Confirming...";
            }
          
          } else {
            confirmed = 2;
            

            if (thelang == 1) {
              status = "Confirmada";
            }
            
            if (thelang == 2) {
              status = "Confirmed";
            }
          }

        }



        if (isremoved) {

          confirmed = 3;

          if (thelang == 1) {
            status = "Removida";
          }
          
          if (thelang == 2) {
            status = "Removed";
          }
        }




        if (destchain == 0) {
          tochain = "Avalanche";

          if (tokenout == '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7') {
            decimals = 6;
            nametoken = "USDT";
            amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
          }  
          
          if (tokenout == '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E') {
            decimals = 6;
            nametoken = "USDC";
            amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
          }
        
          chained = 4;
          feefixed = ethers.utils.formatUnits(events[i]['returnValues']['fee'], decimals);   
        
        }



        if (destchain == 102) {
          tochain = "BNB Chain";
        
          if (tokenout == '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56') {
            decimals = 18;
            nametoken = "BUSD";
            amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
          
          }  
          
          if (tokenout == '0x55d398326f99059fF775485246999027B3197955') {
            decimals = 18;
            nametoken = "USDT";
            amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
          }            
        
          if (confirmed == 2 && confirmations > 12) {
          
            var crossvalues = [];
            crossvalues = await getEventsStargate(CHAIN56, WEB3_ROUTER_ADDRESS56, checktype, thewallet, 56, 43114, events[i]['returnValues']['id']);
          
            chained = crossvalues[0];
            feefixed = ethers.utils.formatUnits(crossvalues[1], decimals); 
          
          }
           
          
          if (confirmed == 3) {
            chained = 5;
            feefixed = ethers.utils.formatUnits(feefixed, decimals); 
          }
        
        }



        
        if (destchain == 109) {
          tochain = "Polygon";

          if (tokenout == '0xc2132D05D31c914a87C6611C10748AEb04B58e8F') {
            decimals = 6;
            nametoken = "USDT";
            amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
          }  
          
          if (tokenout == '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174') {
            decimals = 6;
            nametoken = "USDC";
            amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
          }
        
        
          if (confirmed == 2 && confirmations > 12) {
          
            var crossvalues = [];
            crossvalues = await getEventsStargate(CHAIN137, WEB3_ROUTER_ADDRESS137, checktype, thewallet, 137, 43114, events[i]['returnValues']['id']);
          
            chained = crossvalues[0];
            feefixed = ethers.utils.formatUnits(crossvalues[1], decimals); 
          
          }
        
          if (confirmed == 3) {
            chained = 5;
            feefixed = ethers.utils.formatUnits(feefixed, decimals); 
          }

                
        }

      
        
        
      

    



thearray1.push({

idtransaction: events[i]['returnValues']['id'],
      sender: events[i]['returnValues']['sender'],
      store: events[i]['returnValues']['store'],
      memo: events[i]['returnValues']['memo'],
      amountout: String(amountfixed),          
      hash: events[i]['transactionHash'],
      status: status,
      confirmations: confirmations,

      tokenout: tokenout,
tochain: tochain,
thedate: thedate,
from: 'Avalanche', 			
nametoken: nametoken,
theformatdate: theformatdate,
      decimals: decimals,
confirmed: confirmed,
chained: chained,
feefixed: String(feefixed),
thelink: 'https://snowtrace.io/tx/'	  

},);


   };		









           // Polygon Events
    
        provider = new Web3(CHAIN137);  
        contract = new provider.eth.Contract(AbiRouter0x, WEB3_ROUTER_ADDRESS137);
    
        if (checktype == 1) {    
            //var latest_block = 41520045;
            var latest_block = await provider.eth.getBlockNumber();
            var historical_block = latest_block - 1000; // you can also change the value to 'latest' if you have a upgraded rpc
            
            var events = await contract.getPastEvents(
            'Routed', {filter: {store: thewallet},
             fromBlock: historical_block, toBlock: latest_block });
        }
    
        if (checktype == 2) {    
          //var latest_block = 41520045;
          var latest_block = await provider.eth.getBlockNumber();
          var historical_block = latest_block - 1000; // you can also change the value to 'latest' if you have a upgraded rpc
          
          var events = await contract.getPastEvents(
          'Routed', {filter: {sender: thewallet},
           fromBlock: historical_block, toBlock: latest_block });
      }




    
      for (var i = 0; i < events.length; i++) {
    
        var feefixed = 0;
        var chained = 0;
        var amountfixed;
        var confirmations = 0;
        var tokenout = events[i]['returnValues']['tokenOut'];
              var destchain = events[i]['returnValues']['destChain'];
              var isremoved = events[i]['removed'];
              var block = events[i]['blockNumber'];
              var thedate;

              if (block == null) {
                thedate = Date.now();
              } else {  
                var theblockinfo = await provider.eth.getBlock(block);
                thedate = theblockinfo.timestamp;
              }
              
              thedate = new Date(thedate * 1000);
              var theformatdate = thedate.getFullYear() + '/' + (thedate.getMonth() + 1) + '/' + (thedate.getDate()) + ' ' + (thedate.getHours()) + ':' + 	            (thedate.getMinutes()) + ':' + (thedate.getSeconds());
    
              var status;

                if (block == null) {
                  
                  confirmed = 1;

                  if (thelang == 1) {
                    status = "Pendiente...";
                  }
                  
                  if (thelang == 2) {
                    status = "Pending...";
                  }

                } else {

                  confirmations = latest_block - block;

                  if (confirmations <= 48) {
                    confirmed = 1;
                  
                    if (thelang == 1) {
                      status = "Confirmando...";
                    }
                    
                    if (thelang == 2) {
                      status = "Confirming...";
                    }
                  
                  } else {
                    confirmed = 2;
                  
                    if (thelang == 1) {
                      status = "Confirmada";
                    }
                    
                    if (thelang == 2) {
                      status = "Confirmed";
                    }
                  }

                }


                
                if (isremoved) {
                  
                  confirmed = 3;

                  if (thelang == 1) {
                    status = "Removida";
                  }
                  
                  if (thelang == 2) {
                    status = "Removed";
                  }
                }
   


                      if (destchain == 0) {
                        tochain = "Polygon";
                      
                        if (tokenout == '0xc2132D05D31c914a87C6611C10748AEb04B58e8F') {
                          decimals = 6;
                          nametoken = "USDT";
                          amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                        }  
                        
                        if (tokenout == '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174') {
                          decimals = 6;
                          nametoken = "USDC";
                          amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                        }
                      
                        chained = 4;                      
                        feefixed = ethers.utils.formatUnits(events[i]['returnValues']['fee'], decimals); 
                      }
                      

                      if (destchain == 106) {
                        tochain = "Avalanche";
                      
                        if (tokenout == '0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7') {
                          decimals = 6;
                          nametoken = "USDT";
                          amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                        }  
                        
                        if (tokenout == '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E') {
                          decimals = 6;
                          nametoken = "USDC";
                          amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                        }
           
                        
                        if (confirmed == 2 && confirmations > 512) {
                        
                        var crossvalues = [];
                        crossvalues = await getEventsStargate(CHAIN43114, WEB3_ROUTER_ADDRESS43114, checktype, thewallet, 43114, 137, events[i]['returnValues']['id']);
                        
                        chained = crossvalues[0];
                        feefixed = ethers.utils.formatUnits(crossvalues[1], decimals); 
                      
                      }
           
                      if (confirmed == 3) {
                        chained = 5;
                        feefixed = ethers.utils.formatUnits(feefixed, decimals); 
                      }

                      
                      }








                      if (destchain == 102) {
                        tochain = "BNB Chain";
                  
                          if (tokenout == '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56') {
                            decimals = 18;
                            nametoken = "BUSD";
                            amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                          }  
                          
                          if (tokenout == '0x55d398326f99059fF775485246999027B3197955') {
                            decimals = 18;
                            nametoken = "USDT";
                            amountfixed = ethers.utils.formatUnits(events[i]['returnValues']['amountOut'], decimals);
                          }
                      
                          if (confirmed == 2 && confirmations > 512) {
                          
                            var crossvalues = [];
                            crossvalues = await getEventsStargate(CHAIN56, WEB3_ROUTER_ADDRESS56, checktype, thewallet, 56, 137, events[i]['returnValues']['id']);
                          
                            chained = crossvalues[0];
                            feefixed = ethers.utils.formatUnits(crossvalues[1], decimals); 
                          
                          }
                      
                          if (confirmed == 3) {
                            chained = 5;
                            feefixed = ethers.utils.formatUnits(feefixed, decimals); 
                          }
                      
                        }
    
    
    
      thearray1.push({
      
        idtransaction: events[i]['returnValues']['id'],
              sender: events[i]['returnValues']['sender'],
              store: events[i]['returnValues']['store'],
              memo: events[i]['returnValues']['memo'],
              amountout: String(amountfixed),          
              hash: events[i]['transactionHash'],
              status: status,
              confirmations: confirmations,
    
              tokenout: tokenout,
        tochain: tochain,
        thedate: thedate,
        from: 'Polygon', 			
        nametoken: nametoken,
        theformatdate: theformatdate,
              decimals: decimals,
        confirmed: confirmed,
        chained: chained,
        feefixed: String(feefixed),
        thelink: 'https://polygonscan.com/tx/'	  	
        },);
    
      
           };		
    
    
    
    
    
    
    
    
    
    // Finish Polygon Events
    
    
    
    
    
    
    
    
    
    
    
    
    
          thearray1.sort(function (date1, date2) {
    
      // Sort by date
      // If the first item has a higher number, move it down
      // If the first item has a lower number, move it up
      if (date1.thedate > date2.thedate) return -1;
      if (date1.thedate < date2.thedate) return 1;
          });
    
    
    
    
    
    
    
    
       for (var x = 0; x < thearray1.length; x++) {
    
              thearray2.push(<tr>
          
                <td><div class="text text-start"><a href={thearray1[x]['thelink'] + thearray1[x]['hash']} target="_blank"><small>{(thearray1[x]['hash']).substr(0,6)}...{(thearray1[x]['hash']).substr(-4)}</small></a></div></td>
          
                <td><div class="text text-start"><small>{thearray1[x]['idtransaction']}</small></div></td>         
                <td><div class="text text-start"><small>{thearray1[x]['theformatdate']}</small></div></td>         
                <td><div class="text text-start"><small>{(thearray1[x]['sender']).substr(0,6)}...{(thearray1[x]['sender']).substr(-4)}</small></div></td>
                <td><div class="text text-start"><small>{(thearray1[x]['store']).substr(0,6)}...{(thearray1[x]['store']).substr(-4)}</small></div></td>
                <td><div class="text text-start"><small>{thearray1[x]['from']}</small></div></td>
                <td><div class="text text-start"><small>{thearray1[x]['tochain']}</small></div></td>
                <td><div class="text text-start"><small>{thearray1[x]['memo']}</small></div></td>
                <td><div class="text text-start"><small>{(thearray1[x]['tokenout']).substr(0,6)}...{(thearray1[x]['tokenout']).substr(-4)}</small></div></td>
                <td><div class="text text-start"><small>{thearray1[x]['nametoken']}</small></div></td>
                

                {thearray1[x]['confirmed'] == 1 &&
                <td><div class="text text-start text-warning"><small><div class="spinner-border spinner-border-sm text-dark" role="status"></div> {thearray1[x]['status']}</small></div></td>}
                
                {thearray1[x]['confirmed'] == 2 &&
                <td><div class="text text-start text-success"><small>{thearray1[x]['status']}</small></div></td>}
                
                {thearray1[x]['confirmed'] == 3 &&
                <td><div class="text text-start text-danger"><small>{thearray1[x]['status']}</small></div></td>}
                


                {(thearray1[x]['chained'] == 0 && thelang == 1) &&
<td><div class="text text-start text-warning"><small><div class="spinner-border spinner-border-sm text-dark" role="status"></div> Pendiente...</small></div></td>}

{(thearray1[x]['chained'] == 0 && thelang == 2) &&
<td><div class="text text-start text-warning"><small><div class="spinner-border spinner-border-sm text-dark" role="status"></div> Pending...</small></div></td>}




{(thearray1[x]['chained'] == 1 && thelang == 1) &&
<td><div class="text text-start text-warning"><small><div class="spinner-border spinner-border-sm text-dark" role="status"></div> Confirmando...</small></div></td>}

{(thearray1[x]['chained'] == 1 && thelang == 2) &&
<td><div class="text text-start text-warning"><small><div class="spinner-border spinner-border-sm text-dark" role="status"></div> Confirming...</small></div></td>}




{(thearray1[x]['chained'] == 2 && thelang == 1) &&
<td><div class="text text-start text-success"><small>Confirmada</small></div></td>}

{(thearray1[x]['chained'] == 2 && thelang == 2) &&
<td><div class="text text-start text-success"><small>Confirmed</small></div></td>}






{(thearray1[x]['chained'] == 3 && thelang == 1) &&
<td><div class="text text-start text-danger"><small>Removida</small></div></td>}


{(thearray1[x]['chained'] == 3 && thelang == 2) &&
<td><div class="text text-start text-danger"><small>Removed</small></div></td>}



{chained == 4 &&
<td><div class="text text-start"><small>No</small></div></td>}
                

{thearray1[x]['chained'] == 5 &&
            <td><div class="text text-start text-danger"><small>{thearray1[x]['status']}</small></div></td>}


                
                <td><div class="text text-end"><small>{thearray1[x]['confirmations']}</small></div></td>  
    
                        
                
                <td><div class="text text-end"><small>{thearray1[x]['amountout']}</small></div></td>
    
                <td><div class="text text-end"><small>{thearray1[x]['feefixed']}</small></div></td>













                </tr>);
    
      };
    
      
      setspinner(0);
    
              //thearray.sort();
              //thearray.reverse();
    
              if (thearray2.length == 0) {
    
                if (thelang == 1) {
                  setmensaje("No se encontraron transacciones");
                }
                 
                if (thelang == 2) {
                  setmensaje("No transactions found");
                }
    
              }      
    
    
              setTransacciones(thearray2);
    
    
    }
    







function setWallet(e) {

  setthewallet(e);
  setTransacciones([]);

  }
  

  


    function SelectLanguage() {
      if (thelang == 1) {
        setthelang(2);
      }
    
      if (thelang == 2) {
        setthelang(1);
      }
    
    }
    



  return (

    
<div className="Land">    
<div class="container-sm pt-3">



<p></p>


<nav class="navbar navbar-expand-sm bg-white navbar-dark fixed-top">
  <div class="container-fluid">
  <a class="navbar-brand" href={'../?lang=' + thelang}><img src={pw3} class="img-fluid" width="70" height="auto"></img></a>
    <button class="navbar-toggler bg-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="collapsibleNavbar">
      <ul class="navbar-nav">

        <li class="nav-item">
        {thelang == 1 &&
        <a class="nav-link" href={'https://pay.web3debit.shop?lang=' + thelang}>Paga</a>}

        {thelang == 2 &&
        <a class="nav-link" href={'https://pay.web3debit.shop?lang=' + thelang}>Pay</a>}
        </li>






        <li class="nav-item">
        {thelang == 1 &&
        <a class="nav-link" href="./collect?lang=1">Cobra</a>}

        {thelang == 2 &&
        <a class="nav-link" href="./collect?lang=2">Collect</a>}
        </li>




        



<li class="nav-item">
{thelang == 1 &&
<a class="nav-link" href="https://docs.web3debit.shop/v/spanish" target="_blank">Docs</a>}

{thelang == 2 &&
<a class="nav-link" href="https://docs.web3debit.shop/v/english" target="_blank">Docs</a>}
</li>



<li class="nav-item">
{thelang == 1 &&
<a class="nav-link" onClick={SelectLanguage}><div class="pointer">English</div></a>}

{thelang == 2 &&
<a class="nav-link" onClick={SelectLanguage}><div class="pointer">Español</div></a>}
</li>



      </ul>
    </div>
  </div>
</nav>








<p></p>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>





{thelang == 1 &&
<p class="text3 text-center"><h2><b>Transacciones</b></h2></p>}

{thelang == 2 &&
<p class="text3 text-center"><h2><b>Transactions</b></h2></p>}


{thelang == 1 &&
<p class="text text-center text-dark"><h6>Dirección</h6></p>}

{thelang == 2 &&
<p class="text text-center text-dark"><h6>Address</h6></p>}





<p class="text text-center">


<div class="row">


<div class="col-2">


</div>


<div class="col-8">

{thelang == 1 &&
<input type="text" class="form-control bg-white text-center" value={thewallet} onChange={e => setWallet(e.target.value)} />}


{thelang == 2 &&
<input type="text" class="form-control bg-white text-center" value={thewallet} onChange={e => setWallet(e.target.value)} />}

<p></p>

</div>

<div class="col-md-4">

</div>

</div>


</p>




<p class="text text-center">

{thelang == 1 &&
<button type="button" class="btn btn-primary" onClick={getEventsTo}>Destinatario</button>}

{thelang == 2 &&
<button type="button" class="btn btn-primary" onClick={getEventsTo}>Addressee</button>}

<span> </span>

{thelang == 1 &&
<button type="button" class="btn btn-primary" onClick={getEventsFrom}>Remitente</button>}

{thelang == 2 &&
<button type="button" class="btn btn-primary" onClick={getEventsFrom}>Sender</button>}

</p>




{spinner == 1 &&

<p class="text text-danger text-center"><div class="spinner-border text-dark" role="status"></div></p>}


<p class="text text-danger text-center"><b>{mensaje}</b></p>






<div class="card bg-transparent">
  <div class="card-body card-body-history bg-transparent">


<div class="table-responsive-sm table-borderless bg-transparent">
<table class="table">
<tbody>




<tr>


<td class="text text-start text-muted">
Hash
</td>


<td class="text text-start text-muted">

{thelang == 1 &&
<span>ID</span>}

{thelang == 2 &&
<span>ID</span>}

</td>




<td class="text text-start text-muted">

{thelang == 1 &&
<span>Fecha</span>}

{thelang == 2 &&
<span>Date</span>}

</td>





<td class="text text-start text-muted">

{thelang == 1 &&
<span>Remitente</span>}

{thelang == 2 &&
<span>Sender</span>}

</td>


<td class="text text-start text-muted">

{thelang == 1 &&
<span>Destinatario</span>}

{thelang == 2 &&
<span>Addressee</span>}

</td>







<td class="text text-start text-muted">

{thelang == 1 &&
<span>Desde</span>}

{thelang == 2 &&
  <span>From</span>}


</td>



<td class="text text-start text-muted">

{thelang == 1 &&
<span>Hacia</span>}

{thelang == 2 &&
  <span>To</span>}


</td>

<td class="text text-start text-muted">
Ref #
</td>

<td class="text text-start text-muted">
Token
</td>

<td class="text text-start text-muted">

{thelang == 1 &&
<span>Simbolo</span>}


{thelang == 2 &&
<span>Symbol</span>}


</td>


<td class="text text-start text-muted">

{thelang == 1 &&
<span>Status</span>}



{thelang == 2 &&
<span>Status</span>}

</td>


<td class="text text-start text-muted">

{thelang == 1 &&
<span>Cross-Chain</span>}



{thelang == 2 &&
<span>Cross-Chain</span>}

</td>





<td class="text text-end text-muted">

{thelang == 1 &&
<span>Confirmaciones</span>}



{thelang == 2 &&
<span>Confirmations</span>}

</td>






<td class="text text-end text-muted">

{thelang == 1 &&
<span>Importe</span>}



{thelang == 2 &&
<span>Amount</span>}

</td>


<td class="text text-end text-muted">

{thelang == 1 &&
<span>Comisión</span>}



{thelang == 2 &&
<span>Fee</span>}

</td>






</tr>



{transacciones}

</tbody>
</table>
</div>



</div>
</div>









<p></p>









    
  



<div class="container-sm pt-3 text-center">

<p></p>
  

</div>


</div>

  




</div>

      

);

}


export default Transactions;